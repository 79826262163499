<template>
  <v-app id="App">
    <v-navigation-drawer class="no-print side-nav" clipped :permanent="permanent" fixed v-model="showSideNav"
                          :expand-on-hover="expandOnHover" :mini-variant.sync="mini" app>
      <v-list nav dense>
        <v-spacer></v-spacer>
        <v-list-item-group active-class="deep-yellow--text text--accent-4">
          <v-list-item link to="/customers">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mes clients</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/invoices" @click="showSideNav = false">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mes factures</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/settings">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mon compte</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <AppBar @on-menu-hit="showSideNav = !showSideNav"/>
    <v-main id="App-content">

      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
export default {
  data() {
    return {
      showSideNav: !this.isMobile(),
      mini: !this.isMobile(),
      invoices: [],
      preview: false
    }
  },
  computed: {
    permanent() {
      return !this.isMobile()
    },
    expandOnHover() {
      return !this.isMobile()
    }
  },
  components: {AppBar},
  methods: {
    isMobile() {
      return window.innerWidth < 1000
    }
  }
}
</script>

<style lang="scss">
@import "styles/main";
@import "styles/colors";
@import "styles/ContentPanel";
@import "styles/table";

.filter-input label.v-label {
  padding-left: 1em !important;
}

.filter-input input {
  padding-left: 1em !important;
}

.filter-input .v-input__append-inner {
  padding-right: 1em;
}
#App {

  display: flex;
  flex-direction: column;
  height: 100%;

  .side-nav {
    background-color: $gray;
  }

  header {
    padding-left: 1em;
    padding-right: 1em;
    background-color: #433f3d;
    color: #fff;
    height: 65px;
    font-size: calc(10px + 2vmin);

    .App-name {
      flex: 1;
      font-size: x-large;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      .btn:not(:last-child) {
        margin-right: 1em;
      }
    }
  }

  #App-content {
    display: flex;
  }
}

.clickable {
  cursor: pointer;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}


@media print {

  .no-print {
    display: none !important;
  }

  .content.open {
    background-color: white !important;
    padding: 0 !important;
    margin: 0 !important;
    .edit-panel.open {
      background-color: white !important;
      padding: 0 !important;
      margin: 0 !important;
      border-left: none !important;
      width: 100% !important;
      flex: 1 !important;
    }
  }

  .content {
    background-color: white !important;
  }

  .edit-panel {
    background-color: white !important;
  }

  .quotation-container {

    width: 210mm;


    padding: 0 !important;
    margin: 0 2em 0 0 !important;
    box-shadow: revert !important;

  }

}


</style>
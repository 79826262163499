<template>
  <div class="content-panel">

    <v-toolbar flat class="subheader">
      <div>Bonjour {{user.name}}</div>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark
             small text @click="createNewCompany">
        Nouvelle entreprise
      </v-btn>
    </v-toolbar>
    <div :class="contentClass">
      <div class="right-panel account-container">
        <div class="account-item">
          <h3>Mes entreprises</h3>
          <div v-if="companies.length > 0" class="company-list">
            <div v-for="company in companies" class="company-list-item" v-bind:key="`company-${company.id}`">
              <router-link :to="`/settings/companies/${company.id}`">{{company.name}}</router-link>
            </div>
          </div>
          <div v-else>
            <div>Vous n'avez encore aucune entreprise</div>
            <router-link to="/settings/companies/create">Créer une entreprise</router-link>
          </div>
        </div>


        <div class="account-item">
          <h3>Données personnelle</h3>
          <router-link :to="`/settings/account`">Voir mon profil</router-link>
        </div>

      </div>
      <div :class="editPanelClass">
        <router-view :key="$route.path" name="account" />
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'

export default {

  data() {
    return {
      showEditPanel: false,
    }
  },
  computed: {
    ...mapState({
      user(state) {
        return state.account.user
      },
      companies(state) {
        return state.account.user ? state.account.user.companies : []
      }
    }),
    editPanelClass() {
      if (this.showEditPanel) {
        return "edit-panel open"
      } else return "edit-panel"
    },
    contentClass() {
      if (this.showEditPanel) {
        return "content open"
      } else return "content"
    }
  },
  methods: {
    createNewCompany() {
      if (this.showEditPanel) this.$router.replace('/settings/companies/create')
      else this.$router.push('/settings/companies/create')
    },
    isEditPanelOpen() {
      return !!this.$route.params.id ||
          this.$route.path.indexOf("create") !== -1 ||
          this.$route.path.indexOf("account") !== -1
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("account/findAccountDetails").then(() => next())
  },
  async created() {
    this.showEditPanel = this.isEditPanelOpen()

  },
  updated() {
    this.showEditPanel = this.isEditPanelOpen()

  }
}
</script>

<style lang="scss" scoped>

.account-container {
  padding: 1em;

  .account-item {
    margin-bottom: 1em;
  }
}
</style>
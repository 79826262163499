<template>
  <div class="edit-panel">
    <div class="header no-print">

      <div style="display: flex; align-items: center">
        <v-icon class="menu-icon" @click="close" size="48">mdi-chevron-left</v-icon>
        <div>
          <h2 v-if="isEstimate">Devis n° {{ invoiceNumber }}</h2>
          <h2 v-else>Facture n° {{ invoiceNumber }}</h2>
          <StatusTag :value="invoiceStatus"/>
        </div>
      </div>
      <div class="actions-container">
        <div v-if="message" :class="messageContainerClass">
          <span>{{ message.text }}</span>
        </div>
        <v-btn @click="displayPreview()" icon class="menu-icon">
          <v-icon v-if="!preview">mdi-eye</v-icon>
          <v-icon v-else>mdi-eye-off</v-icon>
        </v-btn>
        <SaveButton :unsaved="unsavedChange" @on-save="save"/>
        <v-menu bottom left offset-y close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="menu-icon">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in estimateMenuItems" :key="`submenu-${i}`" @click="item.action()">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <EstimatePreview v-if="preview" :value="modifiedInvoice"/>
    <EstimateForm v-else :value="modifiedInvoice"/>


  </div>
</template>

<script>
import EstimatePreview from "@/components/EstimatePreview";
import EstimateForm from "@/components/EstimateForm";

import Vue from 'vue'
import {mapState} from 'vuex'
import _ from "lodash";
import SaveButton from "@/components/SaveButton";
import StatusTag from "@/components/StatusTag";
import html2canvas from 'html2canvas'
import jsPdf from "jspdf"
// import puppeteer from 'puppeteer'

export default {
  components: {StatusTag, SaveButton, EstimateForm, EstimatePreview},
  data() {
    return {
      preview: false,
      showAddCustomer: false,
      showEditPanel: false,
      isCreation: false,
      invoice: {
        status: null,
        customerInfo: {},
        sellerInfo: {}
      },
      modifiedInvoice: {
        status: null,
        customerInfo: {},
        sellerInfo: {}
      },
    }
  },
  computed: {
    ...mapState({
      message(state) {
        return state.invoices.message
      },
      estimateMenuItems() {

        let menu = []
        switch (this.invoice.status) {
          case "DRAFT":
            menu.push({
              title: "Valider",
              action: () => this.validateEstimate()
            })
            break;

          case "VALIDATED":
            menu.push({
              title: "Editer",
              action: () => this.setAsDraft()
            }, {
              title: "Envoyer",
              action: () => this.sendEstimate()
            })
        }

        menu.push({
          title: "Supprimer",
          action: () => this.deleteEstimate()
        })

        return menu
      }
    }),
    unsavedChange() {
      let sameInvoice = !_.isEqual(this.invoice, this.modifiedInvoice);
      let isCreation = this.isCreation;
      return sameInvoice || isCreation
    },
    isInvoice() {
      return this.invoice.invoiceId != null
    },
    isEstimate() {
      return this.invoice.estimateId != null
    },
    invoiceNumber() {
      return this.isInvoice ? this.invoice.invoiceId : this.invoice.estimateId
    },
    messageContainerClass() {
      if (this.message && this.message.isError) {
        return "message error"
      } else return "message"
    },
    invoiceStatus() {
      return this.modifiedInvoice.status
    }
  },
  methods: {
    addCustomer(e) {
      e.preventDefault()
      this.showAddCustomer = true
    },
    update(e) {
      this.modifiedInvoice[e.key] = e.value
    },
    updateCustomerInfo(e) {
      Vue.set(this.modifiedInvoice.customerInfo, e.key, e.value);
    },
    validateEstimate() {
      this.$store.dispatch("invoices/validateEstimate", this.invoice.id).then(response => {
        this.invoice = response
        this.modifiedInvoice = _.cloneDeep(response)
      })
    },
    setAsDraft() {
      this.$store.dispatch("invoices/setAsDraft", this.invoice.id).then(response => {
        this.invoice = response
        this.modifiedInvoice = _.cloneDeep(response)
      })
    },
    displayPreview() {
      this.preview = !this.preview
    },
    sendEstimate() {

      //this.printPDF()
      this.preview = true
      const domElement = document.getElementById('quotation-print-container')
      domElement.style.width = "210mm"
      const invoiceId = this.invoice.id
      html2canvas(domElement, {
        width: 794, onclone: (document) => {
          const domElement = document.getElementById('quotation-print-container')
          domElement.style.width = "210mm"
          let page1 = document.getElementById("quotation-page-1")
          console.log(page1)
          page1.style.width = "210mm"
          console.log(document.id)
          if (document.id === 'quotation-page-1') {
            console.log("heu")
          }
        }
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        const doc = new jsPdf('p', 'mm');
        let position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight - 20, 'someAlias', 'FAST');
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight - 20, 'someAlias', 'FAST');
          heightLeft -= pageHeight;
        }

        const pages = doc.internal.getNumberOfPages();

        const pageWidth = doc.internal.pageSize.width;  //Optional
        const pageHeight2 = doc.internal.pageSize.height;  //Optional
        doc.setFontSize(10);  //Optional

        for (let j = 1; j < pages + 1; j++) {
          let horizontalPos = pageWidth / 2;  //Can be fixed number
          let verticalPos = pageHeight2 - 10;  //Can be fixed number
          doc.setPage(j);
          doc.text(`${j} / ${pages}`, horizontalPos, verticalPos, {align: 'center'});
        }

        // doc.save("HTML-Document.pdf");
        let blob = doc.output('blob');
        this.$store.dispatch("invoices/sendEstimate", {id: invoiceId, pdf: blob}).then((response) => {
          this.invoice = response
          this.modifiedInvoice = _.cloneDeep(response)
        })
      })
    },
    deleteEstimate() {
      this.$store.dispatch("invoices/deleteEstimate", this.invoice.id).then(() => {
        this.$router.replace("/invoices")
      })
    },
    displayError(message) {
      this.message = {
        isError: true,
        text: message
      }
    },
    displaySuccess(message) {
      this.message = {
        text: message
      }
    },
    close() {
      this.$router.replace("/invoices")
    },
    save() {
      this.$store.dispatch("invoices/saveEstimate", this.modifiedInvoice).then((updatedInvoice) => {
        this.invoice = updatedInvoice
        this.modifiedInvoice = _.cloneDeep(updatedInvoice)
      })
    },
    checkCreation() {
      let id = parseInt(this.$route.params.id);
      //this.preview = this.$route.query.preview;
      console.log("this.preview : " + JSON.stringify(this.preview))
      this.showEditPanel = !!id || this.$route.path.indexOf("create") !== -1;
      this.isCreation = this.$route.path.indexOf("create") !== -1

      if (id !== this.invoice.id && !this.isCreation) {
        let invoice = this.$store.getters["invoices/getEstimateById"](id);
        this.invoice = invoice ?? {
          status: null,
          customerInfo: {},
          sellerInfo: {}
        }

        this.modifiedInvoice = _.cloneDeep(this.invoice)
        console.log("this.modifiedInvoice.status : " + JSON.stringify(this.modifiedInvoice.status))

      }
    },
  },
  created() {
    this.checkCreation()
  },
  updated() {
    this.checkCreation()
  }
}
</script>

<style lang="scss">
@import "../styles/colors";

.save-icon.dirty.v-icon {
  opacity: 1;
}

.save-icon.v-icon {
  opacity: 0.3;

  transition: opacity ease-in 0.3s;
}

.save-icon.v-icon.v-icon.v-icon--link {
  cursor: default;
}

.save-icon.dirty.v-icon.v-icon.v-icon--link {
  cursor: pointer;
}

.quotation-wrapper {
  background-color: $lighter-gray;
  width: 100%;
}
</style>
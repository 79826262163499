<template>
  <div class="content-panel">

    <v-toolbar flat class="subheader">
      <div>Mes clients</div>
      <v-spacer></v-spacer>
      <v-btn color="primary"
          small text @click="createNewCustomer()">
        Nouveau
      </v-btn>
    </v-toolbar>
    <div :class="contentClass">
      <div class="right-panel">
        <div class="block search-box">
          <v-text-field
              class="filter-input"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              solo
              flat
              label="Rechercher un client"
              single-line
              hide-details
          ></v-text-field>
        </div>
        <div class="block table-container">

          <v-data-table
              :headers="headers"
              :items="customers"
              :search="search"
              :items-per-page="5"
              @click:row="handleClick"
          ></v-data-table>
        </div>
      </div>
      <div :class="editPanelClass">
        <router-view :key="$route.path" name="customer" />
      </div>

    </div>

  </div>
</template>

<script>
import store from "@/store";

export  default {
  data() {
    return {
      search: "",
      showEditPanel: false,
      customers: [],
      fullHeaders: [
        {
          text: 'Nom',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' }
      ]
    }
  },
  computed: {
    headers() {
      if (this.showEditPanel)
        return this.fullHeaders.slice(0, 1)
      else return this.fullHeaders
    },
    editPanelClass() {
      if (this.showEditPanel) {
        return "edit-panel open"
      } else return "edit-panel"
    },
    contentClass() {
      if (this.showEditPanel) {
        return "content open"
      } else return "content"
    }
  },
  methods: {
    handleClick(value) {
      if (this.showEditPanel) {
        this.$router.replace(`/customers/${value.id}`);
      } else {
        this.$router.push(`/customers/${value.id}`);
      }
    },
    createNewCustomer() {
      if (this.showEditPanel) {
        this.$router.replace(`/customers/create`);
      } else {
        this.$router.push(`/customers/create`);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("customers/getCustomers").then(() => next())
  },
  async created() {
    this.showEditPanel = !!this.$route.params.id || this.$route.path.indexOf("create") !== -1;

    this.customers = this.$store.state.customers.customers
  },
  updated() {
    this.showEditPanel = !!this.$route.params.id || this.$route.path.indexOf("create") !== -1;

  }
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="block">
    <div class="block-header">
      <h3>{{title}}</h3>
      <div class="block-header-actions">
        <v-icon @click="toggleExpand" v-if="expandable">{{ expandedIcon }}</v-icon>
      </div>
    </div>
    <slot name="content" />
    <slot v-if="isExpanded" name="content-expanded" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    expandable: Boolean
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    expandedIcon() {
      return this.isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="edit-panel">
    <div class="header">
      <div style="display: flex; align-items: center">
        <v-icon class="menu-icon" @click="close()" size="48">mdi-chevron-left</v-icon>
        <h2>Mon profil</h2>
      </div>
      <div class="actions-container">
        <SaveButton :unsaved="unsavedChange" @on-save="save" />
      </div>

    </div>

    <v-form ref="form">

      <div class="block">
        <span>{{user.name}}</span>
        <v-text-field label="Email" :rules="required"
                      @input="updateField('email', $event)" :value="user.email"></v-text-field>

      </div>
    </v-form>
  </div>
</template>

<script>
import _ from 'lodash'
import SaveButton from "@/components/SaveButton";

export default {
  components: {
    SaveButton
  },
  data() {
    return {
      user: {},
      modifiedUser: {},
      required: [
        value => !!value || "Champ obligatoire",
      ],
    }
  },
  computed: {
    saveButtonClass() {
      return this.unsavedChange ? "save-icon dirty" : "save-icon"
    },
    unsavedChange() {
      return !_.isEqual(this.user, this.modifiedUser)
    }
  },
  methods: {
    save() {

    },
    close() {
      this.$router.replace("/settings")
    },
    fetchUser() {
      if (Object.keys(this.user).length === 0) {
        this.user = this.$store.getters["account/getUser"]
        this.modifiedUser = Object.assign({}, this.user)
      }
    },
    updateField(key, value) {
      this.modifiedUser[key] = value
    }
  },
  created() {
    this.fetchUser()
  },
  updated() {
  }
}
</script>

<style lang="scss" scoped>

</style>
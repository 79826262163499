import { CustomersService } from "@/api";

const state = () => ({
    customers: [],
    loading: false,
    error: null
})

const getters = {
    getById: (state) => (id) => {

        let items = state.customers
        return items.find(item => {
            return item.id === parseInt(id)
        })
    }
}

const actions = {

    getCustomers({state, commit}) {

        if (state.customers.length > 0) {
            return state.customers
        }

        commit("setLoading", true)
        return CustomersService.getCustomers()
            .then(response => {
                commit('setCustomers', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    saveCustomer({commit}, customer) {
        commit("setLoading", true)
        return CustomersService.saveCustomer(customer)
            .then(response => {
                commit('setCustomer', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    }
}

const mutations = {

    setLoading(state, status) {
        if (status) state.error = null
        state.loading = status
    },

    setError(state, error) {
        state.error = error
    },

    setCustomers(state, customers) {
        state.customers = customers
    },

    setCustomer(state, customer) {
        let customerIndex = state.customers.findIndex(c => c.id === customer.id)
        if (customerIndex === -1) {
            state.customers.push(customer)
        } else {
            state.customers[customerIndex] = customer
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
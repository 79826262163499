import axios from 'axios'
import UserService from './UserService'
import InvoicesService from "@/api/InvoicesService";
import CustomersService from "@/api/CustomersService";

axios.defaults.baseURL =  process.env.VUE_APP_BACKEND_HOST;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export {
    UserService,
    InvoicesService,
    CustomersService
}
<template>
  <div style="padding: 2em;">
    <div id="quotation-print-container" class="quotation-container" style="background-color: #999999">

      <div v-for="pageNumber in numberOfPage" v-bind:key="`q-page-${pageNumber}`" :id="`quotation-page-${pageNumber}`" class="quotation-page">

        <div v-if="pageNumber === 1" id="quotation-print-header" class="quotation-header" style="display: flex">

          <div class="seller-info">
            <b>{{ sellerInfo.name }}</b>
            <span>{{ sellerAddress.street}}</span>
            <span>{{ `${sellerAddress.zipCode} ${sellerAddress.city}` }}</span>
            <span v-if="sellerInfo.phone">{{ sellerInfo.phone }}</span>
            <span>{{ sellerInfo.email }}</span>
            <span v-if="sellerInfo.website">{{ sellerInfo.website }}</span>
          </div>
          <div class="quotation-info">
            <span>{{ `Devis n°${invoiceNumber}` }}</span>
            <span>valide jusqu'au {{ quotationDate  }}</span>
          </div>
          <div class="client-info">
            <b>{{ customerInfo.name }}</b>
            <span>{{ customerAddress.street }}</span>
            <span>{{ `${customerAddress.zipCode} ${customerAddress.city}` }}</span>
          </div>
        </div>

        <div class="quotation-item-container">
          <div class="quotation-item-header">
            <div class="item large"><span>Description</span></div>
            <div class="item small"><span>Qte</span></div>
            <div class="item small"><span>Unite</span></div>
            <div class="item medium" style="text-align: center"><span>Prix Unitaire (HT)</span></div>
            <div class="item small" style="text-align: end;padding: 12px 0 12px 8px;">
              <span>Total</span>
            </div>
          </div>

          <div class="quotation-items">
            <QuotationItem v-for="(item, i) in itemsToDisplay['page' + pageNumber].items"
                           :item="item" v-bind:key="`qitem-${i}`"
                           with-total readonly />
          </div>
        </div>

        <div v-if="itemsToDisplay[`page${pageNumber + 1}`] == null" class="quotation-total-container">
          <div>
            <div v-if="discount > 0" class="no-print discount">
              <i>Remise (%)</i>
              <v-text-field v-model="discount" placeholder="en %"></v-text-field>
            </div>

            <div class="no-tax">
              <b>Total HT</b>
              <span>{{ totalHT }} €</span>
            </div>
            <div class="tva">
              <b>TVA 20%</b>
              <span>{{ totalTVA }} €</span>
            </div>
            <!--        <div v-if="discount" class="discount">-->
            <!--          <i>{{ `Remise ${this.discount}%` }}</i>-->
            <!--          <span>{{ totalDiscount }}</span>-->
            <!--        </div>-->
            <div class="with-tax">
              <b>Total (TTC)</b>
              <strong>{{ totalTTC }} €</strong>
            </div>
          </div>


        </div>

        <div v-if="pageNumber === numberOfPage" class="quotation-approval-container">
          <strong>Date et signature du client</strong>
          <span style="margin-bottom: 40px">(Précédée de la mention ‘Bon pour accord’)</span>
          <div style="width: 300px">
            <hr/>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import QuotationItem from "@/components/QuotationItem";
import moment from "moment-timezone";
import DateUtils from "@/lib/dateUtils"

export default {
  components: {
    QuotationItem
  },
  props: {
    value: Object
  },
  data() {
    return {
      mounted: false,
      numberOfPage: 1,
      invoice: this.value ? this.value : {},
      discount: this.value && this.value.discount ? this.value.discount : 0,
      itemsToDisplay: {
        header: true,
        page1: {
          items: []
        },
        footer: false
      }
    }
  },
  computed: {
    pageHeight() {
      return 1115
    },
    pageHeightWithoutMargin() {
      return this.pageHeight - (69 * 2)
    },
    headerHeight() {
      let quotationContainer = window.document.getElementById("quotation-print-header")
      if (this.mounted && quotationContainer)
        return quotationContainer.clientHeight + 34 // 34 margin
      else return null
    },
    approvalContainerHeight(){
      return 142
    },
    sellerInfo() {
      return this.invoice.sellerInfo ?? {}
    },
    sellerAddress() {
      return this.sellerInfo.address ?? {}
    },
    customerInfo() {
      return this.invoice.customerInfo ?? {}
    },
    customerAddress() {
      return this.customerInfo.address ?? {}
    },
    quotationDate() {
      return moment(DateUtils.clean(this.invoice.estimateDate)).format("DD/MM/YYYY")
    },
    invoiceNumber() {
      return this.invoice && this.invoice.invoiceId
          ? this.invoice.invoiceId : this.invoice.estimateId
    },
    totalHT() {

      let total = 0
      if (this.invoice.items) {
        let map = this.invoice.items.map(i => parseFloat(i.price) * parseInt(i.quantity))
        let totalPrice = map.reduce((a, b) => a + b, 0)
        total =  Math.round(totalPrice * 100).toFixed(2) / 100
      }
      return total
    },
    totalTVA() {
      return this.round((this.totalHT * 20) / 100)
    },
    totalTTC() {
      return this.round(this.totalHT + this.totalTVA)
    },
    totalDiscount() {
      return this.discount ? (this.totalPrice * this.discount) / 100 : 0
    },
    totalPriceWithDiscount() {
      return this.totalPrice - this.totalDiscount
    }
  },
  watch: {
    value(invoice) {
      this.invoice = invoice
    },
    mounted(isMounted) {
      if (isMounted) {
        let itemsHeight = this.invoice.items.map(i => this.itemHeight(i))
        let totalEstimateHeight = this.headerHeight +
            this.approvalContainerHeight +
            itemsHeight.reduce((a, b) => a + b, 0);

        let x = totalEstimateHeight / this.pageHeightWithoutMargin;
        this.numberOfPage = Math.ceil(x)
        let itemsLeft = [...this.invoice.items]
        for(let i = 1; i <= this.numberOfPage; i++) {

          let heightLeft = i === 1
              ? this.pageHeightWithoutMargin - this.headerHeight - 64
              : this.pageHeightWithoutMargin

          let numberOfItems = itemsHeight.filter(itemHeight=> {
            if (heightLeft - itemHeight > 0) {
              heightLeft -= itemHeight
              return true
            } else return false
          })

          let pageItems = itemsLeft.splice(0, numberOfItems.length)

          this.itemsToDisplay = this.addItemsToPage(`page${i}`, pageItems)
          pageItems = []
        }
      }
    }
  },
  methods: {
    addItemsToPage(page, items) {
      let tmpPage = {}
      tmpPage[page] = { items}
      return Object.assign(this.itemsToDisplay, tmpPage)
    },
    itemHeight(item) {
      if (item.description.length <= 24) {
        return 64
      } else if (item.description.length > 24 && item.description.length <= 88)
        return 74
      else return 124
    },
    addDiscount() {
      this.discount = 5
    },
    addQuotationItem() {
      this.$store.commit("invoices/addInvoiceItem", this.invoice)
      this.unsavedChange = true
    },
    updateQuotationItem(index, quotationItem) {
      this.$store.commit("invoices/updateInvoiceItem", {
        invoiceId: this.invoice.id,
        item: quotationItem,
        itemIndex: index
      })
      this.unsavedChange = true
    },
    removeQuotationItem() {
      this.unsavedChange = true
    },
    round(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100
    },
  },
  mounted() {
    console.log("mounted")
    this.mounted = true
  }
}
</script>

<style lang="scss" scoped>

.quotation-container {
  flex: 10;
  .quotation-page {
    //width: 210mm;
    height: 297mm;
    padding: 4em;
    //margin: 0 2em 2em 1em;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.18);
    background-color: white;
  }

  .quotation-approval-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  .quotation-item-container-header {
    display: flex;
  }

  .quotation-total-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
    padding-top: 2em;
    box-shadow: 0 -2px 1px -1px #dedede; // FIXME invert


    input {
      height: 30px;
      width: 65px;
    }

    div {

      .no-tax, .tva, .with-tax, .discount {
        display: flex;
        justify-content: space-between;
        width: 250px;
        align-items: center;
        padding-bottom: 3px;

        span {
          padding-left: 4em;
        }
      }

      .tva {
        box-shadow: inset 0 -1px 0 0 #dedede;
        padding-bottom: 8px;
      }

      .with-tax {
        margin-top: 8px;
      }
    }
  }

  .quotation-header {

    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;

    .client-info, .seller-info, .quotation-info {
      display: flex;
      flex-direction: column;
      max-width: 230px;

      span, b {
        display: block;
      }
    }

    .quotation-info {
      text-align: center;
    }

    .client-info {

      text-align: right;
    }
  }

  .quotation-item-container {

    padding-top: 20px;
  }


}

.quotation-item-header {

  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #dedede;
  color: inherit;
  text-decoration: inherit;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .item {
    padding: 12px 8px;
    color: rgba(0, 0, 0, 0.59);
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .item-actions {
    width: 82px;
    height: 41px;
  }

  .item.small {
    flex: 1;
  }

  .item.large {
    flex: 4
  }

  .item.medium {
    flex: 2;
  }
}

@media only screen and (max-width: 1000px) {
  .quotation-container {
    flex: 10;

    .quotation-page {
      width: 210mm;
      height: 297mm;
      padding: 4em;
      //margin: 0 2em 2em 1em;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.18);
      background-color: white;
    }
  }
}

</style>
<template>
  <div class="quotation-item">
    <div :class="`${quotationFieldClass} large`" style="text-align: left">
      <span v-if="preview">{{quotationItem.description}}</span>
      <v-text-field v-else label="Description" :rules="[required, maxLength]"
                    @input="updateField('description', $event)"
                    :value="quotationItem.description"></v-text-field>

    </div>
    <div :class="`${quotationFieldClass} small`">
      <span v-if="preview">{{quotationItem.quantity}}</span>
        <v-text-field v-else label="Qté" type="number" min=1
                    @input="updateField('quantity', $event)"
                    :value="quotationItem.quantity"></v-text-field>

    </div>
    <div :class="`${quotationFieldClass} small`">
      <span v-if="preview">{{quotationItemUnit}}</span>
      <v-select v-else
                name="unit"
                label="Unité"
                :items="units"
                item-text="label"
                item-value="value"
                @input="updateField('unit', $event)"
                :value="quotationItem.unit" ></v-select>

    </div>
    <div :class="`${quotationFieldClass} medium`" style="justify-content: center;">
      <span v-if="preview">{{`${quotationItem.price} €`}}</span>
      <v-text-field v-else placeholder="Prix HT" label="Prix HT"
                    :rules="[required]" type="number" min=0
                    :value="quotationItem.price"
                    @input="updateField('price', $event)"></v-text-field>

    </div>

    <div v-if="withTotal" class="quotation-field small" style="justify-content: flex-end">{{total ? `${total} €` : "0€"}}</div>

    <div v-if="!readonly" class="quotation-actions">
      <v-btn v-if="!preview" text icon @click="commit">
        <v-icon color="green">mdi-check</v-icon>
      </v-btn>
      <v-btn v-if="!preview" text icon @click="remove">
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
      <v-btn v-if="preview" icon @click="preview = false">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>

    <div v-if="preview" class="clickable"><i class="fa fa-times" @click="$emit('on-remove', quotationItem)" /></div>
  </div>
</template>

<script>
import _ from 'lodash'

export default  {
  props: {
    item: Object,
    readonly: Boolean,
    withTotal: Boolean
  },
  data() {
    return {
      preview: this.item && !_.isEmpty(this.item),
      quotationItem: this.item ? this.item : {},
      units: [
        {label: "pièces", value: "PIECE"},
        {label: "jours", value: "DAY"},
        {label: "heures", value: "HOUR"}
      ],
      required: value => !!value || "Champ obligatoire",
      maxLength: value => (value && value.length <= 100) || "Maximum 100 caractères"
    }
  },

  computed: {
    quotationFieldClass() {
      if (this.preview) {
        return "quotation-field preview"
      } else return "quotation-field"
    },
    quotationItemUnit() {
      let unit = this.units.find(u => u.value === this.quotationItem.unit) ?? {};

      if (this.quotationItem.quantity == 1 && unit.label) {
        return unit.label.slice(0, -1)
      }

      return unit.label
    },
    total() {
      let qty = parseInt(this.quotationItem.quantity) ?? 1
      let unitPrice = parseFloat(this.quotationItem.price) ?? 0.0
      return qty * unitPrice
    }
  },
  methods: {
    updateField(name, event) {
      this.quotationItem[name] = event
    },
    commit() {
      this.preview = true
    },
    remove() {
      this.$emit("on-remove")
    }
  }
}
</script>

<style lang="scss" scoped>
.quotation-item:not(:last-child) {
  box-shadow: inset 0 -1px 0 0 #dedede;
}
.quotation-item {
  width: 100%;
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 64px;
  max-height: 124px;
  color: rgba(0, 0, 0, 0.9);

  .quotation-actions {
    min-width: 82px;

  }

  .quotation-field {

    display: flex;
    align-items: center;
    word-break: break-all;

  }

  .quotation-field.preview {
    padding: 12px 8px;;
  }



  .quotation-field.large {
    flex: 4;
  }
  .quotation-field.small {
    flex: 1;
  }
  .quotation-field.medium {
    flex: 2;
  }
}

@media only screen and (max-width: 1000px) {

  .quotation-item {

    .quotation-field {


      span {
        text-align: left;
      }
    }

    .quotation-field.large {
      width: 100%;
      flex: none;
    }
    .quotation-field.small {
      width: 25%;
      flex: none;
    }
    .quotation-field.medium {
      width: 25%;
      flex: none;
    }

    .quotation-field.preview.large {
      flex: 4;
    }

    .quotation-field.preview.small {
      flex: 1;
    }

    .quotation-field.preview.medium {
      flex: 2;
    }
  }
}
</style>
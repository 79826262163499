import axios from "axios";
export default {

    getCustomers() {
        return new Promise((success, die) => {

            axios.get('/customers')
                .then(response => {
                    success(response.data)
                }).catch(die)
        })
    },

    saveCustomer(customer) {

        if (customer.id) {
            return new Promise((success, die) => {

                axios.put('/customers', customer)
                    .then(response => {
                        success(response.data)
                    }).catch(die)
            })
        }

        return new Promise((success, die) => {

            axios.post('/customers', customer)
                .then(response => {
                    success(response.data)
                }).catch(die)
        })
    }
}
<template>
  <div>
    <div style="display: flex">
      <Block title="Le client" expandable>
        <template slot="content">
          <v-text-field label="Nom" :readonly="isReadonly"
                        :value="invoice.customerInfo.name" @input="updateCustomerInfo({key: 'name', value: $event })" />
        </template>
        <template slot="content-expanded">
          <AddressForm name="address" :readonly="isReadonly"
                       :value="invoice.customerInfo.address" @on-change="updateCustomerInfo" />
        </template>
      </Block>
      <Block title="Le devis">
        <template slot="content">
          <DateField name="estimateDate" label="Date de validité du devis" placeholder="sélectionnez une date"
                     :readonly="isReadonly" :value="invoice.estimateDate" @on-update="update"/>
        </template>
      </Block>
    </div>


    <div class="block fullwidth">
      <div class="block-header">
        <h3>Les éléments du devis</h3>
        <v-btn v-if="!isReadonly" color="primary" text small @click="addQuotationItem">Ajouter</v-btn>

      </div>
      <div class="quotation-item-container">
        <div class="quotation-item-header">
          <div class="item large"><span>Description</span></div>
          <div class="item small"><span>Quantité</span></div>
          <div class="item small"><span>Unité</span></div>
          <div class="item medium"><span>Prix HT</span></div>
          <div class="item-actions"><span>&nbsp;</span></div>
        </div>
        <div class="quotation-items">
          <QuotationItem v-for="(item, i) in invoice.items"
                         :readonly="isReadonly"
                         :item="item" v-bind:key="`qitem-${i}`"
                         @on-remove="removeQuotationItem(i, $event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressForm from "@/components/AddressForm";
import DateField from "@/components/DateField";
import QuotationItem from "@/components/QuotationItem";
import Block from "@/components/Block";


export default {
  components: {Block, AddressForm, DateField, QuotationItem},
  props: {
    value: Object
  },
  data() {
    return {
      invoice: this.value,
    }
  },
  computed: {
    isReadonly() {
      return this.invoice.status !== "DRAFT"
    },
  },
  watch: {
    value(invoice) {
      this.invoice = invoice
    },
    invoice(invoice) {
      this.invoice = invoice
    }
  },
  methods: {
    update(e) {
        this.invoice[e.key] = e.value
    },
    updateCustomerInfo(e) {
        this.invoice.customerInfo[e.key] = e.value
    },
    addQuotationItem() {
      if (this.invoice.items) {
        this.invoice.items.push({})
      } else {
        this.invoice.items = [{}]
      }
    },
    updateQuotationItem(itemIndex, quotationItem) {
        this.invoice.items[itemIndex] = quotationItem
    },
    removeQuotationItem(itemIndex) {
      this.invoice.items.splice(itemIndex, 1)
    },
  }
}
</script>

<style lang="scss" scoped>
.quotation-item-header {

  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #dedede;
  color: inherit;
  text-decoration: inherit;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .item {
    padding: 12px 8px;
    color: rgba(0, 0, 0, 0.59);
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .item-actions {
    width: 82px;
    height: 41px;
  }

  .item.small {
    flex: 1;
  }

  .item.large {
    flex: 4
  }

  .item.medium {
    flex: 2;
  }
}
</style>
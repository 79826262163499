import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'

import account from './modules/account'
import customers from './modules/customers'
import invoices from './modules/invoices'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'


const plugins = []
if (debug) plugins.push(createLogger())

export default new Vuex.Store({
    modules: {
        account,
        invoices,
        customers
    },
    strict: debug,
    plugins: plugins
})


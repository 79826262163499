<template>
  <v-app-bar clipped-left app flat fixed class="no-print" width="100%">

    <v-app-bar-nav-icon v-if="isMobile()" @click="toggleSideNav()" dark></v-app-bar-nav-icon>
    <v-toolbar-title class="brand-name" @click="$router.push('/')">
      Soon CRM
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <nav class="app-nav">
<!--      <v-btn @click="createNewQuotation">Créer un devis</v-btn>-->
<!--      <v-btn @click="togglePreviewMode">Aperçu</v-btn>-->
<!--      <v-btn @click="saveQuotation">Sauvegarder</v-btn>-->
    </nav>
  </v-app-bar>
</template>

<script>

export default {
  methods: {
    isMobile() {
      return window.innerWidth < 1000;
    },
    createNewQuotation() {
      let nextQuotationId = this.$store.getters["quotations/nextQuotationId"]
      this.$store.commit("quotations/createNewQuotation", nextQuotationId)
      this.$router.push("/quotations/" + nextQuotationId)
    },
    toggleSideNav() {
      this.$emit("on-menu-hit")
    },
    saveQuotation() {

    },
  }
}
</script>

<style lang="scss">
.v-toolbar__content {
  width: 100%;
}

.brand-name {
  cursor: pointer;
  letter-spacing: 8px;
}
</style>
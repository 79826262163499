<template>
  <div>
    <v-text-field label="Nom de l'adresse" :readonly="readonly"
        :value="address.name" @input="updateField('name', $event)"></v-text-field>

    <v-text-field label="Destinataire" :rules="required" :readonly="readonly"
        :value="address.recipient" @input="updateField('recipient', $event)"></v-text-field>

    <v-text-field label="Numéro et rue" :rules="required" :readonly="readonly"
        :value="address.street" @input="updateField('street', $event)"></v-text-field>

    <v-text-field label="Code postal"  :rules="required" :readonly="readonly"
        :value="address.zipCode" @input="updateField('zipCode', $event)"></v-text-field>

    <v-text-field label="Ville" :rules="required" :readonly="readonly"
        :value="address.city" @input="updateField('city', $event)"></v-text-field>

    <v-select label="Pays" :readonly="readonly"
              :value="address.countryIso3Code"
              @input="updateField('countryIso3Code', $event)"
              item-text="name"
              item-value="isoCode"
              :items="countries"
              :rules="required">
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: Object,
    readonly: Boolean
  },
  data() {
    return {
      address: this.value ? Object.assign({}, this.value) : {},
      countries: [{
        isoCode: "FRA",
        name: "France"
      },{
        isoCode: "GBR",
        name: "Royaume-Uni"
      }],
      required: [
        value => !!value || "Champ obligatoire",
      ],
    }
  },
  watch: {
    value(val) {
      this.address = val ? Object.assign({}, val) : {}
    }
  },
  methods: {
    updateField(name, value) {
      this.address[name] = value
      this.$emit("on-change", {key: this.name, value: this.address})
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
import {UserService} from "@/api";

const state = () => ({
    user: null,
    loading: false,
    error: null
})

const getters = {

    getUser: (state) => {
      return state.user
    },
    getCompanyById: (state) => (id) => {

        if (!state.user) return null

        let items = state.user.companies
        return items.find(item => {
            return item.id === parseInt(id)
        })
    }
}

const actions = {

    findAccountDetails({state, commit}) {

        if (state.user != null) {
            return state.user
        }

        commit("setLoading", true)
        return UserService.findAccountDetails()
            .then(response => {
                commit('setUser', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },
    async getCompanies({state, commit}) {
        if (state.user == null) {
            commit("setLoading", true)
            return UserService.findAccountDetails()
                .then(response => {
                    commit('setUser', response)
                    return response.companies
                })
                .catch((e) => commit('setError', e))
                .finally(() => commit("setLoading", false))
        } else {
            return state.user.companies
        }


    },
    upsertCompany({commit}, company) {
        commit("setLoading", true)
        return UserService.upsertCompany(company)
            .then(response => {
                commit('setCompany', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

}

const mutations = {

    setLoading(state, status) {
        if (status) state.error = null
        state.loading = status
    },

    setError(state, error) {
      state.error = error
    },

    setUser(state, user) {
        state.user = user
    },
    setCompany(state, company) {

        let user = state.user
        if (user) {
            console.log("found user")
            let index =user.companies.findIndex(c => c.id === company.id)
            console.log("index : " + JSON.stringify(index))
            if (index !== -1) {
                user.companies[index] = company
            } else {
                user.companies.push(company)
            }

        console.log("user : " + JSON.stringify(user))
            state.user = user
        }

    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
import {InvoicesService} from "@/api";

const state = () => ({
    invoices: [],
    estimates: [],
    loading: false,
    message: null,
    preview: false
})

const getters = {
    getById: (state) => (id) => {

        let items = state.invoices
        return items.find(item => {
            return item.id === parseInt(id)
        })
    },
    getEstimateById: (state) => (id) => {

        let items = state.estimates
        return items.find(item => {
            return item.id === parseInt(id)
        })
    },
}

const actions = {

    getInvoices({state, commit}) {

        if (state.invoices.length > 0) {
            return state.invoices
        }

        commit("setLoading", true)
        return InvoicesService.getInvoices()
            .then(response => {
                commit('setInvoices', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    getEstimates({state, commit}) {

        if (state.estimates.length > 0) {
            return state.estimates
        }

        commit("setLoading", true)
        return InvoicesService.getEstimates()
            .then(response => {
                commit('setEstimates', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    createNewEstimate({commit}, invoice) {
        commit("setLoading", true)
        return InvoicesService.createEstimate(invoice)
            .then(response => {
                commit('setEstimate', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },
    deleteEstimate({commit}, id) {
        commit("setLoading", true)
        return InvoicesService.deleteEstimate(id)
            .then(() => {
                commit('removeEstimate', id)
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },
    validateEstimate({commit}, id) {
        commit("setLoading", true)
        return InvoicesService.validateEstimate(id)
            .then(response => {
                commit('setEstimate', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    sendEstimate({commit}, id) {
        commit("setLoading", true)
        return InvoicesService.sendEstimate(id)
            .then(response => {
                console.log("response : " + JSON.stringify(response))
                commit('setEstimate', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    setAsDraft({commit}, id) {
        commit("setLoading", true)
        return InvoicesService.setAsDraft(id)
            .then(response => {
                commit('setEstimate', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },
    save({commit}, invoice) {
        commit("setLoading", true)
        return InvoicesService.saveInvoice(invoice)
            .then(response => {
                commit('setMessage', "Devis validé avec succès")
                commit('setInvoice', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },
    saveEstimate({commit}, estimate) {
        commit("setLoading", true)
        return InvoicesService.saveEstimate(estimate)
            .then(response => {
                commit('setEstimate', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },
    addInvoiceItem({commit, getters}, quotationId) {

        let quotation = getters.getById(quotationId)
        commit("addInvoiceItem", quotation)


    },
    togglePreviewMode({state, commit}) {
        commit("setPreviewMode", !state.preview)
    }
}

const mutations = {

    setLoading(state, status) {
        if (status) state.message = null
        state.loading = status
    },

    setError(state, error) {
        state.message = {
            isError: true,
            text: error
        }
    },

    setMessage(state, messge) {
        state.message = {
            isError: false,
            text: messge
        }
    },

    setInvoices(state, invoices) {
        state.invoices = invoices
    },
    removeEstimate(state, id) {
        let index = state.estimates.findIndex(c => c.id === id)
        if (index !== -1) {
            if (state.estimates.length === 1) {
                state.estimates = []
            } else {
                state.estimates.splice(index, 1)
            }
        }
    },
    setEstimates(state, estimates) {
        state.estimates = estimates
    },
    setInvoice(state, invoice) {
        let index = state.invoices.findIndex(c => c.id === invoice.id)
        if (index === -1) {
            state.invoices.push(invoice)
        } else {
            state.invoices[index] = invoice
        }
    },
    setEstimate(state, estimate) {
        let index = state.estimates.findIndex(c => c.id === estimate.id)
        if (index === -1) {
            state.estimates.push(estimate)
        } else {
            state.estimates[index] = estimate
        }
    },
    setPreviewMode(state, preview){
        state.preview = preview
    },
    addInvoiceItem(state, quotation) {
        let index = state.invoices.findIndex(q => q.id === quotation.id)
        if (index !== -1) {
            quotation.items.push({})
            state.invoices[index] = quotation
        }
    },

    updateInvoiceItem(state, {invoiceId, item, itemIndex}) {
        let index = state.invoices.findIndex(q => q.id === invoiceId)
        if (index !== -1) {
            let invoice = state.invoices[index]
            invoice.items[itemIndex] = item
            state.invoices[index] = invoice
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
import Vue from 'vue'
import axios from "axios";
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'

Vue.use(VueKeyCloak, {
    init: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
    },
    onInitError: (error) => {
      console.log("error : " + JSON.stringify(error))
    },
    onReady: () => {

        axios.interceptors.request.use(config => {
            if (Vue.prototype.$keycloak.authenticated) {
                config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
            }
            return config
        }, error => {
            return Promise.reject(error)
        })
    },
    config: {
        url: `${process.env.VUE_APP_IDS_HOST}/auth`,
        realm: 'soon-agency',
        clientId: 'crm-web',
        "enable-cors": true,
        flow: "implicit"
    },
    logout: {
        redirectUri: `${process.env.VUE_APP_HOST}/`
    },
})

export default VueKeyCloak


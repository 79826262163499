<template>
  <span :class="tagClass">{{ tagText }}</span>
</template>

<script>
export default {
  props: {
    value: String
  },
  data() {
    return {
      status: this.value,
      tagsClass: {
        DRAFT: {
          text: "Brouillon",
          tagClass: "tag red"
        },
        VALIDATED: {
          text: "Validé",
          tagClass: "tag green"
        },
        ISSUED: {
          text: "Emis",
          tagClass: "tag orange"
        },
        ACCEPTED: {
          text: "Accepté",
          tagClass: "tag green"
        },
        REJECTED: {
          text: "Rejeté",
          tagClass: "tag red"
        },
        CANCELLED: {
          text: "Annulée",
          tagClass: "tag black"
        },
        PAID: {
          text: "Payée",
          tagClass: "tag green"
        },
      }
    }
  },
  computed: {
    tagClass() {
      let tag = this.tagsClass[this.status];
      return tag ? tag.tagClass : "tag red"
    },
    tagText() {
      let tag = this.tagsClass[this.status];
      return tag ? tag.text : null
    }
  },
  watch: {
    value(val) {
      console.log("val : " + JSON.stringify(val))
      this.status = val
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/colors";

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 18px 0 8px;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}



.tag::after {
  background: $lighter-gray;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag.red {
  background-color: $primary-color !important;
  color: white;
}

.tag.red::after {
  border-left-color: $primary-color !important;
}

.tag.green {
  background-color: $green !important;
  color: black;
}

.tag.green::after {
  border-left-color: $green !important;
}

.tag.orange {
  background-color: $orange !important;
  color: black;
}

.tag.orange::after {
  border-left-color: $orange !important;
}

.tag.black {
  background-color: $black !important;
  color: white;
}

.tag.black::after {
  border-left-color: $black !important;
}
</style>
<template>
  <div class="content-panel">
    <v-toolbar flat class="subheader no-print">
      <div>Mes devis et factures</div>
      <v-spacer></v-spacer>
      <v-menu bottom left close-on-content-click offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" text v-bind="attrs" v-on="on">
            Nouveau
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, index) in invoicesNewMenuItems" :key="index"
                       @click="createNewInvoice(item.url)" :disabled="item.disabled">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <div :class="contentClass">
      <div class="right-panel no-print">
        <div class="block search-box">
          <v-text-field
              label="Recherche par n°, nom ou date"
              prepend-inner-icon="mdi-magnify"
              hide-details
              flat
              solo
          ></v-text-field>
        </div>
        <div class="block fullwidth no-print">
          <div class="block-header">
            <h2>Les devis</h2>
          </div>
          <div :class="invoicesItemContainerClass">

            <v-data-table
                :headers="headers"
                :items="estimates"
                @click:row="selectQuotation">

              <template v-slot:item.status="{ item }">
                <StatusTag :value="item.status"/>
              </template>
              <template v-slot:item.estimateDate="{ item }">
                {{
                  showEditPanel
                  ? formatDate(item.estimateDate, 'DD/MM/YY')
                  : formatDate(item.estimateDate, 'Do MMMM YYYY')
                }}
              </template>
            </v-data-table>
          </div>
        </div>
      </div>

      <div :class="editPanelClass">
        <router-view :key="$route.path" name="quotation"/>
      </div>
    </div>

  </div>
</template>

<script>
import store from "@/store";
import moment from "moment-timezone";
import {mapState} from "vuex";
import DateUtils from "@/lib/dateUtils";
import StatusTag from "@/components/StatusTag";

export  default {
  components: {StatusTag},
  data() {
    return {
      invoices: [],
      invoicesNewMenuItems: [{
        title: "Nouveau devis",
        url: "/estimates/create"
      }, {
        title: "Nouvelle facture",
        url: "/invoices/create",
        disabled: true
      }],
      showEditPanel: false,
      fullHeaders: [
        {
          text: 'Client',
          value: 'customerInfo.name',
        },
        { text: 'Date de validité', value: 'estimateDate' },
        { text: 'Status', value: 'status' }
      ]
    }
  },
  computed: {
    ...mapState({
      estimates(state) {
        return state.invoices.estimates
      }
    }),
    headers() {
      if (this.showEditPanel)
        return this.fullHeaders
      else return this.fullHeaders
    },
    editPanelClass() {
      if (this.showEditPanel) {
        return "edit-panel open"
      } else return "edit-panel"
    },
    invoicesItemContainerClass() {
      if (!this.showEditPanel) {
        return "no-print table-container quotation-list-container fullwidth"
      } else return "no-print table-container quotation-list-container"
    },
    contentClass() {
      if (this.showEditPanel) {
        return "content open"
      } else return "content"
    }
  },
  methods: {
    selectQuotation(item) {
      this.$router.push(`/invoices/${item.id}`)
    },
    formatDate(date, format) {
      let cleanedDate = DateUtils.clean(date);
      return cleanedDate ? moment(cleanedDate).format(format) : ''
    },
    removeQuotation(id) {
      this.$store.dispatch("invoices/deleteEstimate", id)
    },
    createNewInvoice(url) {
      if (this.showEditPanel) {
        this.$router.replace(url);
      } else {
        this.$router.push(url);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("invoices/getEstimates").then(() => next())
  },
  async created() {
    this.showEditPanel = !!this.$route.params.id || this.$route.path.indexOf("create") !== -1;
    this.invoices = await this.$store.dispatch("invoices/getEstimates")
  },
  updated() {
    this.showEditPanel = !!this.$route.params.id || this.$route.path.indexOf("create") !== -1;

  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/colors";

.quotation-list-container > .quotation-list-item {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.quotation-list-container > .quotation-list-item ~ .quotation-list-item {
  border-top: none;
}

.quotation-list-container.fullwidth {
  flex: 1;

  .quotation-list-item {
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.quotation-list-container {
  flex: 1;
  background-color: white;

  h3 {
    padding-left: 1em;
  }

  .quotation-list-item {
    display: flex;
    justify-content: space-between;
    max-height: 135px;

    .client-info {
      display: flex;
      height: 100%;
      flex-direction: column;
      flex: 2;
      padding: 0.5em;
    }

    .danger {
      color: #c4372a;
      flex: 1;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .danger:hover {

      i {

        color: white;
      }
      flex: 1;
      background-color: #c4372a;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .quotation-list-item:hover {
    background-color: rgba(0, 0, 0, 0.04);;

    .danger {
      background-color: $dark-gray;
    }

    .danger:hover {
      color: white;
      background-color: #c4372a;
    }
  }
}
</style>
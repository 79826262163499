<template>
  <div class="customer-edit-panel">
    <div class="header">
      <div style="display: flex; align-items: center">
        <v-icon class="menu-icon" @click="close()" size="48">mdi-chevron-left</v-icon>
        <h2 v-if="!isCreation">Fiche client</h2>
        <h2 v-else>Ajouter un nouveau client</h2>
      </div>
      <div class="actions-container">
        <SaveButton :unsaved="unsavedChange" @on-save="save" />
      </div>

    </div>

    <v-form ref="form">
      <div class="block">
          <h3>Données administratives</h3>
        <v-text-field label="Nom" :rules="required"
            :value="customer.name" @input="updateField('name', $event)"></v-text-field>

        <v-checkbox
            :value="customer.isPrivate"
            @input="updateField('isPrivate', $event)"
            :readonly="!isEdition"
            label="Client particulier"
        ></v-checkbox>
        <v-text-field label="Email" :rules="required"
            @input="updateField('email', $event)" :value="customer.email"></v-text-field>

        <v-text-field label="Téléphone"
            @input="updateField('phone', $event)" :value="customer.phone"></v-text-field>

      </div>

      <div class="block">
        <h3>Addresse</h3>
        <AddressForm name="address" :value="customer.address" @on-change="updateAddress"/>
        <h3>Addresse de livraison</h3>
        <v-checkbox v-model="deliverySameAsAddress" label="Identique à l'adresse principale"></v-checkbox>
        <AddressForm v-if="!deliverySameAsAddress" name="deliveryAddress" :value="customer.deliveryAddress" @on-change="updateAddress" />
        <h3>Addresse de facturation</h3>
        <v-checkbox v-model="billingSameAsAddress" label="Identique à l'adresse principale"></v-checkbox>
        <AddressForm v-if="!billingSameAsAddress" name="billingAddress" :value="customer.billingAddress" @on-change="updateAddress" />
      </div>
    </v-form>
  </div>
</template>

<script>
import AddressForm from "@/components/AddressForm";
import _ from "lodash"
import SaveButton from "@/components/SaveButton";

export  default {
  components: {SaveButton, AddressForm },
  data() {
    return {
      isCreation: false,
      isEdition: true,
      billingSameAsAddress: true,
      deliveryAddress: null,
      deliverySameAsAddress: true,
      customer: {},
      modifiedCustomer: {},
      required: [
        value => !!value || "Champ obligatoire",
      ],

    }
  },
  computed: {
    saveButtonClass() {
      return this.unsavedChange ? "save-icon dirty" : "save-icon"
    },
    unsavedChange() {
      return !_.isEqual(this.customer, this.modifiedCustomer) || this.isCreation
    }
  },
  methods: {
    checkCreation() {
      let id = parseInt(this.$route.params.id);
      this.isCreation = this.$route.path.indexOf("create") !== -1

      if (id !== this.customer.id && !this.isCreation) {
        let customer = this.$store.getters["customers/getById"](id);
        this.customer = customer
        this.modifiedCustomer = Object.assign({}, customer)
      }

    },
    updateField(a, b) {
      this.modifiedCustomer[a] = b
    },
    updateAddress(elt) {
      this.modifiedCustomer[elt.key] = elt.value
    },
    close() {
      this.$router.replace("/customers")
    },
    save() {

      if (this.unsavedChange && this.$refs.form.validate()) {
        let customer = {
          id: this.modifiedCustomer.id,
          userId: this.$keycloak.subject,
          name: this.modifiedCustomer.name,
          email: this.modifiedCustomer.email,
          phone: this.modifiedCustomer.phone,
          description: this.modifiedCustomer.description,
          address: this.modifiedCustomer.address,
          billingAddress: this.modifiedCustomer.billingAddress,
          deliveryAddress: this.modifiedCustomer.deliveryAddress
        }

        this.$store.dispatch("customers/saveCustomer", customer)
            .then((response) => {
                if (this.isCreation) this.$router.replace("/customers/" + response.id)
              else this.customer = response
            }).catch(error => {
          console.log("error : " + JSON.stringify(error))
        })
      }
    }
  },
  created() {
    this.checkCreation()
  },
  updated() {
    this.checkCreation()
  }
}
</script>

<style lang="scss">
@import "../styles/colors";

.menu-icon {
  font-size: x-large;
  opacity: 0.7;
  transition: opacity ease-in 0.3s;
  i {
    font-size: 30px !important;
  }
}

.menu-icon.unsaved {
  opacity: 1;
}

.menu-icon:hover {
  opacity: 1;
}


.close-icon {

  cursor: pointer;
  position: relative;

  width: 32px;
  height: 25px;
  opacity: 0.3;
  transition: opacity ease-in 0.3s;
}

.close-icon:hover {
  opacity: 1;
}

.close-icon:before, .close-icon:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 25px;
  width: 2px;
  background-color: #333;
}
.close-icon:before {
  transform: rotate(45deg);
}

.close-icon:after {
  transform: rotate(-45deg);
}
</style>
<template>
  <v-btn @click="save()" icon :class="menuIconClass" :disabled="!unsavedChange">
    <v-badge v-if="unsavedChange" color="primary" dot overlap >
      <v-icon >mdi-content-save</v-icon>
    </v-badge>
    <v-icon v-else>mdi-content-save</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    unsaved: Boolean
  },
  data() {
    return {
      unsavedChange: this.unsaved
    }
  },
  watch: {
    unsaved(value) {
      this.unsavedChange = value
    }
  },
  methods: {
    save() {
      this.$emit("on-save")
    }
  },
  computed: {
    menuIconClass() {
      if (this.unsavedChange) {
        return "menu-icon unsaved"
      } else return "menu-icon"
    }
  }
}
</script>
<template>
    <div class="field-container">

        <v-menu v-model="show"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                readonly
                :disabled="readonly"
                @click="togglePicker"
                :label="label"
                :value="computedDateFormattedMomentjs"
                :placeholder="placeholder"
                v-bind="attrs"
                v-on="on" />
          </template>
          <v-date-picker
              v-model="insideValue"
              no-title
              @input="update">
          </v-date-picker>
        </v-menu>
    </div>
</template>

<script>

    import moment from 'moment-timezone'
    import DateUtils from "@/lib/dateUtils"

    export default {
        name: 'DateField',
        data() {
          return {
              show: false,
              insideValue: this.value
          }
        },
        props: {
            label: String,
            name: String,
            value: [String, Object],
            placeholder: String,
          readonly: Boolean
        },
      computed: {
        computedDateFormattedMomentjs () {
          let cleanedDate = DateUtils.clean(this.insideValue);
          return this.insideValue ? moment(cleanedDate).format('Do MMMM YYYY') : ''
        },
      },
        methods: {
            togglePicker(e) {
                if (!this.readonly) {
                  this.show = !this.show
                } else {
                  e.preventDefault()
                }
            },
            update() {
                this.show = false
                this.$emit('on-update', {key: this.name, value: moment(this.insideValue)})
            }
        }
    }
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Invoice from "@/views/Invoice";
import Invoices from "@/views/Invoices";
import Customers from "@/views/Customers";
import Customer from "@/views/Customer";
import EstimateCreation from "@/views/EstimateCreation";
import Account from "@/views/Account";
import Company from "@/views/Company";
import Profile from "@/views/Profile";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/settings',
    component: Account,
    children: [{
      path: 'companies/create',
      components: {
        account: Company
      }
    }, {
      path: 'companies/:id',
      components: {
        account: Company
      }
    }, {
        path: "account",
        components: {
          account: Profile
        }
    }]
  },
  {
    path: '/customers',
    component: Customers,
    children: [{
      path: 'create',
      components: {
        customer: Customer
      }
    }, {
      path: ':id',
      components: {
        customer: Customer
      }
    }]
  },
  {
    path: '/invoices',
    component: Invoices,
    children: [{
      path: 'create',
      components: {
        quotation: EstimateCreation
      }
    }, {
      path: ':id',
      components: {
        quotation: Invoice
      }
    }]
  },
  {
    path: '/estimates',
    component: Invoices,
    children: [{
      path: 'create',
      components: {
        quotation: EstimateCreation
      }
    }, {
      path: ':id',
      components: {
        quotation: Invoice
      }
    }]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

router.beforeEach(async (to, from, next) => {

    while (!router.app.$keycloak.ready) {
      console.log("waitin kc to be ready")
      await sleep(100)
    }

    if (router.app.$keycloak.authenticated) {
      next()
    } else {
      let redirectUri = process.env.VUE_APP_HOST + to.fullPath;
      const loginUrl = router.app.$keycloak.createLoginUrl({redirectUri: redirectUri})
      window.location.replace(loginUrl)
    }
})



export default router

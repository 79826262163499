<template>
  <div class="invoice-creation">
    <div class="header">
      <h2>Nouveau devis</h2>
      <!--        <v-icon @click="isEdition = !isEdition">mdi-pencil</v-icon>-->
      <span @click="close" class="close-icon"></span>
    </div>

    <div v-if="companies.length > 1" class="block">
      <h2>Quelle entreprise émet la devis ?</h2>
      <v-autocomplete
          v-model="companyModel"
          :search-input.sync="companySearch"
          label="Sélectionez une entreprise"
          :items="companies"
          item-text="Description"
          return-object></v-autocomplete>
    </div>
    <div v-else-if="companies.length === 1" class="block">
      Facture émise par {{companies[0].name}}
    </div>
    <div v-else class="block">
      <router-link :to="`/settings/companies/create`">Ajouter une entreprise</router-link>
    </div>
    <div class="block">
      <h2>Quel est le client ?</h2>
      <v-autocomplete
          v-model="clientModel"
          :search-input.sync="clientSearch"
          label="Sélectionez un client"
          :items="clients"
          item-text="Description"
          return-object></v-autocomplete>
    </div>
    <div class="block">
      <h2>Sélectionner une date de validité</h2>
      <DateField name="estimateLimitDate" label="Date de validité du devis" placeholder="sélectionnez une date"
                 :value="estimateLimitDate" @on-update="update"/>
    </div>
<!--    <div>-->
<!--      <h2>Sélectionez le vendeur</h2>-->
<!--      <v-select label="Sélectionner un vendeur" :items="sellers"></v-select>-->
<!--    </div>-->
    <div style="display: flex; justify-content: end">
      <v-btn color="primary" @click="generateEstimates()">Générer le devis</v-btn>
    </div>
  </div>
</template>

<script>
import DateField from "@/components/DateField";
import moment from "moment";

export default {
  components: { DateField },
  data() {
    return {
      descriptionLimit: 60,
      clientModel: null,
      clientEntries: [],
      clientSearch: null,
      companyModel: null,
      companyEntries: [],
      companySearch: null,
      sellers: [],
      estimateLimitDate: null
    }
  },
  computed: {
    clients() {
      return this.clientEntries.map(entry => this.addDescription(entry, entry.name))
    },
    companies() {
      return this.companyEntries.map(entry => this.addDescription(entry, entry.name))
    }
  },
  watch: {
    companies(values) {
      if(values && values.length === 1) {
        this.companyModel = values[0]
      }
    }
  },
  methods: {
    update(v) {
      this[v.key] = v.value
    },
    addDescription(obj, text) {
      const Description = text.length > this.descriptionLimit
          ? text.slice(0, this.descriptionLimit) + '...'
          : text

      return Object.assign({}, obj, { Description })
    },
    close() {
      this.$router.replace("/invoices")
    },
    generateEstimates() {

      let inp = this.estimateLimitDate;
      console.log("inp : " + JSON.stringify(inp))

      moment(inp).toISOString()
      console.log("moment(this.estimateLimitDate) : " + JSON.stringify(moment(inp)))

      this.$store.dispatch("invoices/createNewEstimate", {
        sellerInfo: this.companyModel,
        customerInfo: this.clientModel,
        estimateDate: moment(inp)
      }).then((estimate) => {
        this.$router.replace("/invoices/" + estimate.id)
      })

    }
  },
  async created() {
    this.clientEntries = await this.$store.dispatch("customers/getCustomers")
    this.companyEntries = await this.$store.dispatch("account/getCompanies")
  }
}
</script>

<style lang="scss" scoped>
.invoice-creation {

}
</style>
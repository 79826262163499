var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-panel"},[_c('v-toolbar',{staticClass:"subheader no-print",attrs:{"flat":""}},[_c('div',[_vm._v("Mes devis et factures")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_vm._v(" Nouveau ")])]}}])},[_c('v-list',_vm._l((_vm.invoicesNewMenuItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.createNewInvoice(item.url)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('div',{class:_vm.contentClass},[_c('div',{staticClass:"right-panel no-print"},[_c('div',{staticClass:"block search-box"},[_c('v-text-field',{attrs:{"label":"Recherche par n°, nom ou date","prepend-inner-icon":"mdi-magnify","hide-details":"","flat":"","solo":""}})],1),_c('div',{staticClass:"block fullwidth no-print"},[_vm._m(0),_c('div',{class:_vm.invoicesItemContainerClass},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.estimates},on:{"click:row":_vm.selectQuotation},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('StatusTag',{attrs:{"value":item.status}})]}},{key:"item.estimateDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showEditPanel ? _vm.formatDate(item.estimateDate, 'DD/MM/YY') : _vm.formatDate(item.estimateDate, 'Do MMMM YYYY'))+" ")]}}])})],1)])]),_c('div',{class:_vm.editPanelClass},[_c('router-view',{key:_vm.$route.path,attrs:{"name":"quotation"}})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-header"},[_c('h2',[_vm._v("Les devis")])])}]

export { render, staticRenderFns }
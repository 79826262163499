<template>
  <div class="home">

    <router-link to="/customers">Mes clients</router-link>
    <router-link to="/quotations">Les devis</router-link>
  </div>
</template>

<script>

export default {
}
</script>

<template>
  <div class="company-edit-panel">
    <div class="header">
      <div style="display: flex; align-items: center">
        <v-icon class="menu-icon" @click="close()" size="48">mdi-chevron-left</v-icon>
        <h2 v-if="isCreation">Ajouter une entreprise</h2>
        <h2 v-else>Fiche entreprise</h2>
      </div>

      <div class="actions-container">
        <SaveButton :unsaved="unsavedChange" @on-save="save" />
      </div>
    </div>

      <v-form ref="form">
        <div class="block">
          <h3>Données administratives</h3>
          <v-text-field label="Nom de l'entreprise" :rules="required"
              :value="company.name" @input="updateField('name', $event)"></v-text-field>

          <v-text-field label="Email" :rules="required"
              :value="company.email" @input="updateField('email', $event)"></v-text-field>

          <v-text-field label="Téléphone"
              :value="company.phone" @input="updateField('phone', $event)"></v-text-field>

          <v-text-field
              label="Numéro d'enregitrement (optionnel)"
              :value="company.companyRegistrationNumber" @input="updateField('companyRegistrationNumber', $event)"></v-text-field>

          <v-text-field
              label="Lieu d'enregitrement (optionnel)"
              :value="company.companyRegistrationLocation" @input="updateField('companyRegistrationLocation', $event)"></v-text-field>

          <v-text-field label="Id de TVA (optionnel)"
              :value="company.vatIdNumber" @input="updateField('vatIdNumber', $event)"></v-text-field>
        </div>
        <div class="block">
          <h3>Addresse de l'entreprise</h3>
          <AddressForm name="address" :value="company.address" @on-change="updateAddress"/>
          <h3>Addresse de facturation</h3>
          <v-checkbox v-model="sameAsAddress" label="Identique à l'adresse principale"></v-checkbox>
          <AddressForm v-if="!sameAsAddress" name="billingAddress" :value="company.billingAddress" @on-change="updateAddress" />
        </div>

        <Block title="Configuration smtp">
          <template slot="content">
            <SmtpConfigForm name="smtpConfig" :value="smtpConfig" @on-change="updateSmtpConfig"/>
          </template>
        </Block>

      </v-form>
  </div>
</template>

<script>
import {mapState} from "vuex";
import AddressForm from "@/components/AddressForm";
import _ from "lodash";
import SaveButton from "@/components/SaveButton";
import SmtpConfigForm from "@/components/SmtpConfigForm";
import Block from "@/components/Block";

const defaultCompany = () => {
  return {
    companyRegistrationNumber: null,
    companyRegistrationLocation: null,
    vatIdNumber: null,
    companyPreferences: {
      smtpConfig: null
    }
  }
}

export default {
  components: {
    SmtpConfigForm,
    AddressForm,
    SaveButton,
    Block
  },
  data() {
    return {
      isCreation: false,
      company: defaultCompany(),
      modifiedCompany: defaultCompany(),
      required: [
        value => !!value || "Champ obligatoire",
      ],
    }
  },
  computed: {
    ...mapState({
      sameAsAddress() {
        return !this.company.billingAddress
      },
      smtpConfig(){
        return this.company && this.company.companyPreferences
            ? this.company.companyPreferences.smtpConfig
            : {}
      },
      isLoading(state) {
        return state.account.loading
      },
      errorMessage(state) {
        return state.account.error
      }
    }),
    saveButtonClass() {
      return this.unsavedChange ? "save-icon dirty" : "save-icon"
    },
    unsavedChange() {
      return !_.isEqual(this.company, this.modifiedCompany) || this.isCreation
    }
  },
  methods: {
    close() {
      this.$router.replace("/settings")
    },
    save() {
      if (this.unsavedChange && this.$refs.form.validate()) {
        this.$store.dispatch("account/upsertCompany", this.modifiedCompany)
            .then((company) => {
              if (this.isCreation) this.$router.replace("/settings/companies/" + company.id)
              else {
                this.company = Object.assign(defaultCompany(), company)
              }
            }).catch(() => {

            })
      }
    },
    updateSmtpConfig(field) {
      let newPreferences = {}
      newPreferences[field.key] = field.value
      this.modifiedCompany.companyPreferences = Object.assign({},
          this.modifiedCompany.companyPreferences, newPreferences)
    },
    updateAddress(field) {
      this.modifiedCompany[field.key] = field.value
    },
    updateField(key, value) {
      this.modifiedCompany[key] = value
    },
    checkCreation() {
      let id = parseInt(this.$route.params.id)
      this.isCreation = this.$route.path.indexOf("create") !== -1

      if (this.isCreation) {
        this.company = {}
        this.modifiedCompany = {}
      }

      if (id !== this.company.id && !this.isCreation) {
        let company = this.$store.getters["account/getCompanyById"](id) ?? {};
        this.company = Object.assign(defaultCompany(), company)
        this.modifiedCompany = Object.assign(defaultCompany(), company)
      }
    },
  },
  created() {
    this.checkCreation()
  },
  updated() {
    this.checkCreation()
  }
}
</script>

<style lang="scss" scoped>

</style>
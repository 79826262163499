import axios from "axios";
export default {

    getInvoices() {
        return new Promise((success, die) => {

            axios.get('/invoices')
                .then((invoices) => {
                    success(invoices.data)
                }).catch(die)
        })
    },

    getEstimates() {
        return new Promise((success, die) => {

            axios.get('/estimates')
                .then((response) => {
                    success(response.data)
                }).catch(die)
        })
    },

    createEstimate(invoice) {
        return new Promise((success, die) => {
            axios.post('/estimates', invoice)
                .then(response => {
                    success(response.data)
                }).catch(die)
        })
    },

    deleteEstimate(id) {
        return new Promise((success, die) => {
            axios.delete(`/estimates/${id}`)
                .then(success)
                .catch(die)
        })
    },

    saveEstimate(estimate) {

        if (estimate.id) {
            return new Promise((success, die) => {

                axios.put('/estimates', estimate)
                    .then(response => {
                        success(response.data)
                    }).catch(die)
            })
        }

        return new Promise((success, die) => {

            axios.post('/estimates', estimate)
                .then(response => {
                    success(response.data)
                }).catch(die)
        })
    },

    validateEstimate(id) {
        return new Promise((success, die) => {

            axios.put(`/estimates/${id}/validate`, null, {
                validateStatus: (status) => status < 500
            }).then(response => {
                if (response.status === 200) {
                    success(response.data)
                } else if (response.status === 304) {
                    die("Le devis est déjà validé")
                } else {
                    die("Une erreur est survenue")
                }
            }).catch(die)
        })
    },

    setAsDraft(id) {
        return new Promise((success, die) => {

            axios.put(`/estimates/${id}/draft`, null, {
                validateStatus: (status) => status < 500
            }).then(response => {
                if (response.status === 200) {
                    console.log("response.data.items.length : " + JSON.stringify(response.data.items.length))
                    success(response.data)
                } else if (response.status === 304) {
                    die("Le devis ne peux plus être édité")
                } else {
                    die("Une erreur est survenue")
                }
            }).catch(die)
        })
    },

    sendEstimate(id) {
        return new Promise((success, die) => {

            let formData = new FormData()
            formData.set("file", id.pdf)

            axios.post(`/estimates/${id.id}/send`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    success(response.data)
                }).catch(die)
        })
    },

    saveInvoice(invoice) {

        if (invoice.id) {
            return new Promise((success, die) => {

                axios.put('/invoices', invoice)
                    .then(response => {
                        success(response.data)
                    }).catch(die)
            })
        }

        return new Promise((success, die) => {

            axios.post('/invoices', invoice)
                .then(response => {
                    success(response.data)
                }).catch(die)
        })
    }
}
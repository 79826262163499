import axios from 'axios'

export default {

    async findAccountDetails() {
        return new Promise((success, die) => {
            axios.get("/account").then(response => {
                success(response.data)
            }).catch(die)
        })
    },

    async upsertCompany(company) {

        if (company.id) {
            return new Promise((success, die) => {
                axios.put("/account/companies", company).then(response => {
                    success(response.data)
                }).catch(die)
            })
        } else {
            return new Promise((success, die) => {
                axios.post("/account/companies", company).then(response => {
                    success(response.data)
                }).catch(die)
            })
        }
    },
}
<template>
  <div>
        <v-text-field label="Adresse du serveur (host)" 
                      :value="smtpConfig.host" @input="updateField('host', $event)"></v-text-field>

        <v-text-field label="Port" type="numeric" :rules="required" 
                      :value="smtpConfig.port" @input="updateField('port', $event)"></v-text-field>

        <v-text-field label="Nom d'utilisateur" :rules="required"
                      :value="smtpConfig.username" @input="updateField('username', $event)"></v-text-field>

        <v-text-field label="Mot de passe" type="password" :rules="required"
                      :value="smtpConfig.password" @input="updateField('password', $event)"></v-text-field>

        <v-text-field label="Envoyeur" :rules="required" 
                      :value="smtpConfig.sender" @input="updateField('sender', $event)"></v-text-field>

  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: Object
  },
  data() {
    return {
      smtpConfig: this.value ? this.value : {},
      required: [
        value => !!value || "Champ obligatoire",
      ],
    }
  },
  watch: {
    value(val) {
      this.smtpConfig = val ? this.value : {}
    }
  },
  methods: {
    updateField(name, value) {
      this.smtpConfig[name] = value
      this.$emit("on-change", {key: this.name, value: this.smtpConfig})
    }
  }
}
</script>

<style lang="scss" scoped>


</style>